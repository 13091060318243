import api from '../api';
import {
    AUTH_ENDPOINT,
    JOURNEYS_ENDPOINT,
    CARD_DECISIONS_ENDPOINT,
    CARD_DECISION_ENDPOINT,
    CARD_PREVIEW_ENDPOINT,
    CARD_TEMPLATES_ENDPOINT,
    PROVIDER_BASKET_ENDPOINT,
    RECORD_TO_DC_ENDPOINT,
    CUSTOMERS_ENDPOINT,
    USERS_ENDPOINT,
    TIMES_RECOMMENDED_ENDPOINT,
    PHA_USER_ENDPOINT,
    AVATAR_ENDPOINT,
    WALLET_CARDS_ENDPOINT,
    CPT_GROUPS_ENDPOINT,
    DECISION_FORM_TEMPLATES_ENDPOINT
} from './constants';

class ApiServices {
    refreshToken = async ({token}) => await api.post(`${AUTH_ENDPOINT}/token/refresh`, {refresh_token: token}, {
        noRefresh: true,
        headers: {Authorization: `Bearer ${token}`}
    });

    getJourney = ({journeyId}) => api.get(`${JOURNEYS_ENDPOINT}/${journeyId}`);

    getCardDecisions = ({journeyId}) => api.get(`${CARD_DECISIONS_ENDPOINT}/${journeyId}`);

    updateCardDecision = ({journeyId, cardDecision}) => api.post(`${CARD_DECISION_ENDPOINT}/${journeyId}`, cardDecision);

    getCardPreview = ({journeyId, decisionCenterFormData}) => api.post(`${CARD_PREVIEW_ENDPOINT}/${journeyId}`, decisionCenterFormData);

    getCardTemplates = ({journeyId, locale}) => api.get(`${CARD_TEMPLATES_ENDPOINT}/${journeyId}`, {locale});

    // getProviderList = ({limit = 50, use_ribbon_health, is_location_search_enabled = use_ribbon_health, offset = 0, ...params}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/records`, {...params, limit, is_location_search_enabled, offset});
    getProviderList = ({limit = 50, offset = 0, ...params}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/records`, {...params, limit, offset});

    getProvider = ({npi, profileId, useRibbonHealth, searchId}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}`, {profile_id: profileId, use_ribbon_health: useRibbonHealth, search_id: searchId});

    updateProvider = ({npi, provider}) => api.put(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}`, provider);

    getServiceDetailList = ({npi}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/service_details`);

    addServiceDetail = ({npi, savedFields}) => api.post(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/service_details`, savedFields);

    updateServiceDetail = ({npi, serviceDetailId, savedFields}) => api.put(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/service_details/${serviceDetailId}`, savedFields);

    deleteServiceDetail = ({npi, serviceDetailId}) => api.delete(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/service_details/${serviceDetailId}`);

    getNotGoodOptionsList = ({npi}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/not_good_options`);

    addNotGoodOption = ({npi, savedFields}) => api.post(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/not_good_options`, savedFields);

    updateNotGoodOption = ({npi, notGoodOptionId, savedFields}) => api.put(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/not_good_options/${notGoodOptionId}`, savedFields);

    deleteNotGoodOption = ({npi, notGoodOptionId}) => api.delete(`${PROVIDER_BASKET_ENDPOINT}/records/${npi}/not_good_options/${notGoodOptionId}`);

    getNetworks = ({query, limit = 20}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/networks`, {query, limit}, {isLoader: false});

    getSpecialtyGroups = ({query, limit = 20}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/specialty_groups`, {query, limit}, {isLoader: false});

    getSpecialtyGroupById = specialtyGroupId => api.get(`${PROVIDER_BASKET_ENDPOINT}/specialty_groups/${specialtyGroupId}`);

    getSpecialties = ({query, limit = 20}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/specialties`, {query, limit}, {isLoader: false});

    getSearchContextList = ({searchContextId, limit = 50, offset = 0}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/search_contexts`, {user_id: searchContextId, limit, offset});

    getSearchContext = ({searchId}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/search_contexts/${searchId}`);

    getSteerageTypes = () => api.get(`${PROVIDER_BASKET_ENDPOINT}/steerage_types`);

    getCustomSteerageTypes = ({customerId}) => api.get(`${PROVIDER_BASKET_ENDPOINT}/custom_steerage_types`, {user_id: customerId});

    addRecordToTicket = ({journeyId, npi}) => api.post(`${RECORD_TO_DC_ENDPOINT}/${journeyId}/${npi}`);

    getCustomer = ({customerId}) => api.get(`${CUSTOMERS_ENDPOINT}/${customerId}`);

    getCustomerAccessToken = ({customerId}) => api.post(`${USERS_ENDPOINT}/${customerId}/access-token`);

    getTimesRecommended = ({npi, page = 1}) => api.get(`${TIMES_RECOMMENDED_ENDPOINT}/${npi}`, {page});

    getPHAUser = () => api.get(`${PHA_USER_ENDPOINT}`);

    updateAvatar = formData => api.post(`${AVATAR_ENDPOINT}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});

    getWalletCards = ({customerId}) => api.get(`${WALLET_CARDS_ENDPOINT}/${customerId}`);

    getCptGroups = ({query, limit = 20}) => api.get(`${CPT_GROUPS_ENDPOINT}/autocomplete`, {query, limit}, {isLoader: false});

    getKisx = () => api.get(`${DECISION_FORM_TEMPLATES_ENDPOINT}`);

    getProcedureById = procedureId => api.get(`${PROVIDER_BASKET_ENDPOINT}/procedures/${procedureId}`);
}

export default new ApiServices();
