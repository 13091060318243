import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useSelector} from 'react-redux';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import {Form, Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import NetworksAutocomplete from '../../shared/NetworksAutocomplete';
import SpecialtyGroupAutocomplete from '../../shared/SpecialtyGroupAutocomplete';
import ProvidersSearchResults from '../ProvidersSearchResults';
import {getJourneyAddress, getJourneyInitialAddress, getProviderSearchExtraParams} from '../../../selectors/decisionCenter';
import {PROVIDER_TYPES} from '../../../constants';
import {parseAddress} from '../../../helpers';
import {negate, equal, validateRequired, validateInt, validateLength, getItemKeyValue} from '../../../utils';
import {DISTANCE_OPTIONS, STATE_POSTAL_CODE_OPTIONS, GENDER_OPTIONS} from '../../../options';

const NPI_TOOLTIP_CONTENT = 'Search by NPI is ignored other fields';
const NETWORKS_TOOLTIP_CONTENT = 'Only HealthJoy records';
const GENDER_TOOLTIP_CONTENT = 'Additional parameter for searching by Specialty type';

const JOURNEY_ADDRESS_KEYS = ['zip_code', 'state', 'city', 'street_address'];
const validateNPILength = value => {
    const validate10 = validateLength(10);
    const validate15 = validateLength(15);

    return validate10(value) && validate15(value) ? 'Must be 10 or 15 characters' : undefined;
};

const validateZIPLength = validateLength(5);

/* istanbul ignore next */
const validate = values => {
    const {first_name: firstName, last_name: lastName, organization_name: organizationName, specialty_id: specialtyId, npi, zip, state, city} = values;
    const areNamesValidationAvailable = [specialtyId, npi, organizationName].every(negate);
    const isOrganizationNameValidationAvailable = [specialtyId, npi, firstName, lastName].every(negate);
    const isSpecialtyIdValidationAvailable = [npi, organizationName, firstName, lastName].every(negate);
    const isAddressFieldsValidationAvailable = [firstName, lastName, organizationName, specialtyId].some(Boolean);
    const isZipValidationAvailable = isAddressFieldsValidationAvailable && !state && !city;
    const isStateValidationAvailable = isAddressFieldsValidationAvailable && !zip && !city;
    const isCityValidationAvailable = isAddressFieldsValidationAvailable && !zip && !state;

    return {
        first_name: areNamesValidationAvailable ? validateRequired(firstName) : undefined,
        last_name: areNamesValidationAvailable ? validateRequired(lastName) : undefined,
        organization_name: isOrganizationNameValidationAvailable ? validateRequired(organizationName) : undefined,
        specialty_id: isSpecialtyIdValidationAvailable ? validateRequired(specialtyId) : undefined,
        npi: npi ? (validateInt(npi) || validateNPILength(npi)) : undefined,
        zip: (zip && (validateInt(zip) || validateZIPLength(zip))) || (isZipValidationAvailable && validateRequired(zip)) || undefined,
        state: isStateValidationAvailable ? validateRequired(state) : undefined,
        city: isCityValidationAvailable ? validateRequired(city) : undefined
    };
};

const getFilteredObjectByExistValues = obj => Object.fromEntries(Object.entries(obj).filter(([_, value]) => !equal(value, undefined) && !equal(value, null) && !equal(value, '')));

const ProvidersSearch = ({className, decisionType}) => {
    const [searchingParams, setSearchingParams] = useState({});
    const [areResultsLoaded, setAreResultsLoaded] = useState(false);
    const [networks, setNetworks] = useState([]);
    const [specialtyName, setspecialtyName] = useState('');
    const providerSearchExtraParams = useSelector(getProviderSearchExtraParams);
    const journeyInitialAddress = useSelector(getJourneyInitialAddress);
    const journeyAddress = useSelector(getJourneyAddress);
    const initialValues = {...parseAddress(journeyAddress), use_ribbon_health: true, ...providerSearchExtraParams};

    const onSubmit = async values => {
        const {
            specialty_id: specialtyId,
            provider_type: providerType,
            lat,
            lon,
            gender,
            use_ribbon_health: useRibbonHealth,
            ...restValues
        } = values;
        const isGenderAvailable = !providerType || equal(providerType, PROVIDER_TYPES.individual);
        const specialtyTypeParams = specialtyId
            ? {provider_type: providerType, gender: isGenderAvailable ? gender : ''}
            : {};

        const parsedJourneyInitialAddress = parseAddress(journeyInitialAddress);
        const isSameJourneyAddresses = JOURNEY_ADDRESS_KEYS.every(key => equal(values[key]?.trim(), parsedJourneyInitialAddress[key]));
        const coordinates = isSameJourneyAddresses && lat && lon ? {lat, lon} : {};

        const updatedValues = getFilteredObjectByExistValues({
            ...restValues,
            ...coordinates,
            ...specialtyTypeParams,
            specialty_id: specialtyId,
            specialty_name: specialtyName,
            use_ribbon_health: useRibbonHealth
        });

        setSearchingParams(updatedValues);
        setAreResultsLoaded(true);
    };

    return (
        <div className={classnames('provider-search', className)}>
            <ContentSection>
                <Heading className='mb-10' type={HEADING_TYPES['4']}>Search for providers</Heading>

                <Form initialValues={initialValues} validate={validate} keepDirtyOnReinitialize onSubmit={onSubmit}>
                    {({handleSubmit, values, form}) => {
                        const {
                            first_name: firstName,
                            last_name: lastName,
                            organization_name: organizationName,
                            specialty_id: specialtyId,
                            provider_type: providerType
                        } = values;
                        const areNameFieldsDisabled = [organizationName, specialtyId].some(Boolean);
                        const isOrganizationNameFieldDisabled = [firstName, lastName, specialtyId].some(Boolean);
                        const isSpecialtyIdFieldDisabled = [firstName, lastName, organizationName].some(Boolean);
                        const isAdditionalSpecialtyTypeFieldsDisabled = !specialtyId;
                        const isGenderFieldDisabled = equal(providerType, PROVIDER_TYPES.organization);

                        const onChangeSpecialty = value => {
                            const {id, name} = value;
                            setspecialtyName(name);

                            form.change('specialty_id', id);
                        };

                        const onChangeNetworkIds = values => {
                            setNetworks(values);
                            form.change('network_ids', values.map(getItemKeyValue('id')));
                        };

                        return (
                            <form onSubmit={handleSubmit}>
                                <div className='mb-15'>
                                    <Field name='profile_id'>{props => <Input {...props} type='hidden'/>}</Field>
                                    <Field name='lat'>{props => <Input {...props} type='hidden'/>}</Field>
                                    <Field name='lon'>{props => <Input {...props} type='hidden'/>}</Field>

                                    <Row>
                                        <Column sm={8} className='mb-10'>
                                            <Alert type={ALERT_TYPES.info} description='Search is performed only by one of the fields: First / Last name, Organization name, Specialty type, NPI'/>
                                        </Column>
                                        <Column sm={4} className='mb-10 mt-5'>
                                            <Field name='use_ribbon_health'>
                                                {props => <Switcher {...props} caption='Use Ribbon Health'/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Row className='mb-10'>
                                        <Column sm={3}>
                                            <Field name='first_name' className='mb-12'>
                                                {props => <Input {...props} label='First name' data-testid='first_name' disabled={areNameFieldsDisabled}/>}
                                            </Field>
                                        </Column>
                                        <Column sm={3}>
                                            <Field name='last_name' className='mb-12'>
                                                {props => <Input {...props} label='Last name' data-testid='last_name' disabled={areNameFieldsDisabled}/>}
                                            </Field>
                                        </Column>
                                        <Column sm={3}>
                                            <Field name='organization_name' className='mb-12'>
                                                {props => <Input {...props} label='Organization Name' data-testid='organization_name' disabled={isOrganizationNameFieldDisabled}/>}
                                            </Field>
                                        </Column>

                                        <Column sm={2}>
                                            <Field name='npi'>
                                                {props => (
                                                    <Input {...props}
                                                        label={<LabeledTooltip title='NPI' content={NPI_TOOLTIP_CONTENT}/>}
                                                        placeholder='Enter NPI'/>
                                                )}
                                            </Field>
                                        </Column>

                                        <Column sm={5}>
                                            <Field name='specialty_id'>{props => (
                                                <SpecialtyGroupAutocomplete {...props}
                                                    label='Specialty type'
                                                    data-testid='specialty_id'
                                                    // ribbon_health={values.use_ribbon_health}
                                                    disabled={isSpecialtyIdFieldDisabled}
                                                    onChange={onChangeSpecialty}/>
                                            )}
                                            </Field>
                                        </Column>

                                        <Column sm={2}>
                                            <Field name='gender'>{props => (
                                                <Select {...props}
                                                    label={<LabeledTooltip title='Gender' content={GENDER_TOOLTIP_CONTENT}/>}
                                                    data-testid='gender'
                                                    disabled={isAdditionalSpecialtyTypeFieldsDisabled || isGenderFieldDisabled}
                                                    options={GENDER_OPTIONS}/>
                                            )}
                                            </Field>
                                        </Column>

                                        <Separator/>

                                        <Column sm={2}>
                                            <Field name='zip_code'>{props => <Input {...props} label='ZIP'/>}</Field>
                                        </Column>

                                        <Column sm={2}>
                                            <Field name='state'>{props => <Select {...props} label='State' options={STATE_POSTAL_CODE_OPTIONS}/>}</Field>
                                        </Column>
                                        <Column sm={3}>
                                            <Field name='city'>{props => <Input {...props} label='City'/>}</Field>
                                        </Column>
                                        <Column sm={4}>
                                            <Field name='street_address'>{props => <Input {...props} label='Address'/>}</Field>
                                        </Column>

                                        <Separator/>

                                        <Column sm={2}>
                                            <Field name='radius'>{props => <Select {...props} label='Distance' options={DISTANCE_OPTIONS}/>}</Field>
                                        </Column>

                                        <Column sm={8}>
                                            <NetworksAutocomplete label={<LabeledTooltip title='Networks' content={NETWORKS_TOOLTIP_CONTENT}/>}
                                                networks={networks}
                                                onChange={onChangeNetworkIds}/>
                                        </Column>
                                    </Row>

                                    <Row end='sm'>
                                        <Column sm={2}>
                                            <Button type={BUTTON_TYPES.primary} isSubmit>Search</Button>
                                        </Column>
                                    </Row>
                                </div>
                            </form>
                        );
                    }}
                </Form>

                {areResultsLoaded && <ProvidersSearchResults searchingParams={searchingParams} decisionType={decisionType}/>}
            </ContentSection>
        </div>
    );
};

ProvidersSearch.propTypes = {
    className: PropTypes.string,
    decisionType: PropTypes.string
};

ProvidersSearch.defaultProps = {
    className: ''
};

export {ProvidersSearch as TestableProvidersSearch};
export default React.memo(ProvidersSearch);
